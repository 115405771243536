const createPostMessageClient = (senderOrigin, handlers, target, msgValidator = null) => {
	const handlerTypes = Object.keys(handlers);

	const receiveMessage = (event) => {
		if (event.origin === senderOrigin) {
			if (!msgValidator || msgValidator(event)) {
				const type = event.data?.type;

				if (type && handlerTypes.includes(type)) {
					handlers[type](event.data.data, type, event);
				}
			}
		}
	};

	window.addEventListener("message", receiveMessage);

	return {
		send: (type, data) => {
			const doSend = (target) => 	target.postMessage({ data, type }, senderOrigin);

			if (target.then && target.catch) {
				target.then(doSend);
			} else {
				doSend(target);
			}
		},
		close: () => {
			window.removeEventListener("message", receiveMessage);
		},
	};
};

export default createPostMessageClient;
