import { debug } from "rtUtils/logger";
import createPostMessageClient from "rtUtils/postMessageClient";
import { APP_EVENTS, TAG_EVENTS } from "shared/events";
import { WIDGET_EVENTS } from "./consts";
import { createElement } from "./utils";
import { getAppConfig } from "./utils/configHelper";

const WAIT_FOR_LOAD_MS = 100;
const WAIT_ATTEMPTS = 20;

const APP_EVENTS_TO_TRIGGER_MAP = {
	[APP_EVENTS.ANALYTICS]: WIDGET_EVENTS.ANALYTICS,
	[APP_EVENTS.MODEL_INFO]: WIDGET_EVENTS.MODEL_INFO,
	[APP_EVENTS.MOUSE_MOVE]: WIDGET_EVENTS.MOUSE_MOVE,
	[APP_EVENTS.SCREENSHOT]: WIDGET_EVENTS.SCREENSHOT,
	[APP_EVENTS.RENDERING_API_READY]: WIDGET_EVENTS.RENDERING_API_READY,
};

const createIframeElement = (viewerId, updateableConfig) => {
	const config = updateableConfig.getConfig();

	const iframe = createElement("iframe", {
		frameborder: "no",
		allow: "",
		width: "100%",
		height: "100%",
		title: "Dimensions Viewer",
	}, null, { test: "d8s-viewer-iframe", viewerId });

	iframe.style.border = "none";
	iframe.style.background = "transparent";
	iframe.style.display = config.autoShow ? "block" : "none";

	return iframe;
};

const createListenerTriggersFromAppEvents = (eventsMap, eventsManager) => {
	return Object.entries(eventsMap)
		.reduce((res, [app, widget]) => {
			res[app] = (data) => {
				eventsManager.trigger({ type: widget, data });
			};
			return res;
		}, {});
};

const createIframe = (viewerId, updateableConfig, eventsManager) => {
	let appReadyReceived = false;
	let appInitSent = false;
	let isDestroyed = false;
	let isShowing = false;
	let comms = null;
	let container = null;
	let iframe = null;
	let initSendTimerHandler = null;
	let loadAttempts = 0;

	const sendAppConfig = () => {
		if (container) {
			if (!appInitSent) {
				comms.send(TAG_EVENTS.INIT, {
					config: getAppConfig(updateableConfig.getConfig()),
				});

				if (appReadyReceived) {
					appInitSent = true;
				}
			} else {
				comms.send(TAG_EVENTS.UPDATE, {
					config: getAppConfig(updateableConfig.getConfig()),
				});
			}
		}
	};

	const checkIsValid = () => {
		if (isDestroyed) {
			throw new Error(`Dimensions Viewer (${viewerId}) - Iframe was destroyed and cannot be used`);
		}
	};

	const config = updateableConfig.getConfig();
	isShowing = config.autoShow;

	iframe = createIframeElement(viewerId, updateableConfig);

	container = config.container;

	if (container) {
		container.appendChild(iframe);
	}

	const url = `${config.appUrl}?origin=${location.origin}&viewerId=${viewerId}`;
	iframe.setAttribute("src", url);

	const createComms = (config) => {
		comms = createPostMessageClient(
			new URL(config.appUrl).origin,
			{
				[APP_EVENTS.READY]: (data) => {
					debug(`VIEWER TAG (${viewerId}) - received READY event from App`, data);
					appReadyReceived = true;

					sendAppConfig();
				},
				[APP_EVENTS.CUSTOM_AR_QR_MODAL]: (data) => {
					const latestConfig = updateableConfig.getConfig();
					latestConfig.ar.customArQrRenderer({
						container: latestConfig.container,
						onClose: () => comms.send(TAG_EVENTS.CLOSE_AR_QR_MODAL),
						getQr: () => "data:image/svg+xml;base64," + btoa(data.qr),
						...data,
					});
				},
				...createListenerTriggersFromAppEvents(APP_EVENTS_TO_TRIGGER_MAP, eventsManager),
			},
			iframe.contentWindow,
			({ data }) => data?.data?.viewerId === viewerId,
		);
	};

	const onFrameLoaded = () => {
		debug(`VIEWER TAG (${viewerId}) - Iframe load event - creating pm comms`);
		createComms(config);
		iframe.removeEventListener("load", onFrameLoaded);
		sendAppConfig();

		if (initSendTimerHandler) {
			clearTimeout(initSendTimerHandler);
			initSendTimerHandler = null;
		}
	};

	const manualIframeLoad = () => {
		if (iframe.contentWindow) {
			//mimic load event received
			onFrameLoaded();
		} else if (loadAttempts < WAIT_ATTEMPTS) {
			initSendTimerHandler = setTimeout(manualIframeLoad, WAIT_FOR_LOAD_MS);
		}

		loadAttempts += 1;
	};

	if (container) {
		iframe.addEventListener("load", onFrameLoaded);
		initSendTimerHandler = setTimeout(manualIframeLoad, WAIT_FOR_LOAD_MS);
	}

	return {
		changeContainer: (newContainer) => {
			checkIsValid();

			if (container) {
				container.removeChild(iframe);
			}

			debug(`VIEWER TAG (${viewerId}) - changing container - `, newContainer);
			newContainer.appendChild(iframe);
			container = newContainer;
		},

		update: () => {
			checkIsValid();

			if (!appInitSent) {
				//most likely, update was called on a viewer that didnt receive a container when initialized
				initSendTimerHandler = setTimeout(manualIframeLoad, WAIT_FOR_LOAD_MS);
			} else {
				sendAppConfig();
			}
		},

		show: () => {
			checkIsValid();

			iframe.style.display = "block";
			isShowing = true;
		},

		hide: () => {
			checkIsValid();

			iframe.style.display = "none";
			isShowing = false;
		},

		destroy: () => {
			checkIsValid();

			container.removeChild(iframe);
			iframe = null;
			container = null;
			isDestroyed = true;
			isShowing = false;
			comms?.close();
			comms = null;
		},

		sendTagEvent: (event, data) => {
			checkIsValid();
			if (appInitSent) {
				comms.send(event, data);
			}
		},
	};
};

export default createIframe;
