export const WIDGET_EVENTS = Object.freeze({
	INIT: "WIDGET_INIT",
	RENDERED: "WIDGET_RENDERED",
	ANALYTICS: "WIDGET_ANALYTICS",
	SCREENSHOT: "SCREENSHOT",
	AR_QR_MODAL: "AR_QR_MODAL",
	MOUSE_MOVE: "MOUSE_MOVE",
	MODEL_INFO: "MODEL_INFO",
	RENDERING_API_READY: "RENDERING_API_READY",
});

