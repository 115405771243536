const createEventsManager = () => {
	const listeners = new Set();

	const addListener = (fn) => {
		if (!listeners.has(fn)) {
			listeners.add(fn);
		}

		//return unregister fn
		return () => {
			if (listeners.has(fn)) {
				listeners.delete(fn);
			}
		};
	};

	const trigger = (name, ...args) => {
		listeners.forEach((listener) => {
			listener(name, ...args);
		});
	};

	const clear = () => listeners.clear();

	return {
		addListener,
		trigger,
		clear,
	};
};

export default createEventsManager;
