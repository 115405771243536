import getGlobal from "rtUtils/getGlobal";
import merge from "rtUtils/merge";
import { WIDGET_EVENTS } from "./consts";
import { getAppUrl } from "./utils";
import DEFAULTS from "./defaults";
import initializeViewer from "./initializeViewer";
import createEventsManager from "./eventsManager";
import getWidgetApi from "./widgetApi";

const root = getGlobal();

const widgetAppUrlFromScript = getAppUrl();

let viewerCounter = 0;

const getWidgetConfig = (userConfig) => {
	let config = merge({ appUrl: widgetAppUrlFromScript }, DEFAULTS, userConfig);

	return {
		update: (newConfig) => {
			config = merge({}, config, newConfig);
			return config;
		},
		getConfig: () => config,
	};
};

root.initDimensionsViewer = (config) => {
	const updateableConfig = getWidgetConfig(config);
	const eventsManager = createEventsManager();

	viewerCounter += 1;

	const {
		render,
		iframe,
	} = initializeViewer(`d8s-viewer-${viewerCounter}`, updateableConfig, eventsManager);

	if (updateableConfig.getConfig().autoShow) {
		render();
	}

	return getWidgetApi(updateableConfig, eventsManager, render, iframe);
};

root.initDimensionsViewer.EVENTS = WIDGET_EVENTS;
