export const APP_EVENTS = {
	READY: "APP_READY",
	ANALYTICS: "APP_ANALYTICS",
	SCREENSHOT: "SCREENSHOT",
	CUSTOM_AR_QR_MODAL: "CUSTOM_AR_QR_MODAL",
	MOUSE_MOVE: "MOUSE_MOVE",
	MODEL_INFO: "MODEL_INFO",
	RENDERING_API_READY: "RENDERING_API_READY",
};

export const TAG_EVENTS = {
	INIT: "TAG_INIT",
	TERMINATE: "TAG_TERMINATE",
	UPDATE: "TAG_UPDATE",
	CLOSE_AR_QR_MODAL: "CLOSE_AR_QR_MODAL",

	//undocumented/debug events:
	EXPORT_GLTF: "EXPORT_GLTF",
	PRINT_STRUCTURE: "PRINT_STRUCTURE",
	GET_MODEL_INFO: "GET_MODEL_INFO",
};
