const createElement = (type, attrs, className, data, win = null) => {
	win = win || self;
	const element = win.document.createElement(type);

	attrs = attrs || {};

	if (className) {
		attrs.class = className;
	}

	if (attrs) {
		Object.keys(attrs).forEach((key) => element.setAttribute(key, attrs[key]));
	}

	if (data) {
		Object.keys(data).forEach((key) => element.dataset[key] = data[key]);
	}

	return element;
};

export default createElement;
