import { getIsDebug } from "rtUtils/logger";
import DEFAULTS from "../defaults";

const isFunction = (val) => !!val && typeof val === "function";

//config elements to change before PM serializes
const CONFIG_PROP_TRANSFORMS = {
	"ar.customArQrRenderer": isFunction,
};

//config objects to clone so we don't modify the tag's internal config  (currently only works on first level elements)
const CONFIG_CLONE_OBJECTS = ["ar"];

//config element to remove altogether (currently only works on first level elements)
const EXCLUDED_CONFIG_PROPS = ["container"];

const transformConfig = (config) => {
	const cloned = [];

	Object.entries(CONFIG_PROP_TRANSFORMS)
		.forEach(([path, transformer]) => {
			const paths = Array.isArray(path) ? [].concat(path) : path.split(".");
			let parent = config, lastChildName = paths.shift(), child = config[lastChildName];

			//find the config element that requires transforming and its parent object
			while (paths.length && child !== undefined) {
				const newParent = child;
				if (typeof child === "object" && !Array.isArray(child) ) {
					lastChildName = paths.shift();
					child = child[lastChildName];
					parent = newParent;
				}
			}

			if (child && parent) {
				parent[lastChildName] = transformer(child);
			}
		});

	//pass on debug flag if not specifically false
	config.debug = config.debug !== false && getIsDebug();

	return config;
};

export const getAppConfig = (config) => {
		//copy all the config params that exist in the defaults
		const appConfig = Object.keys(DEFAULTS)
			.reduce((res, name) => {
				if (!EXCLUDED_CONFIG_PROPS.includes(name)) {
					if (CONFIG_CLONE_OBJECTS.includes(name)) {
						res[name] = { ...config[name] };
					} else {
						res[name] = config[name];
					}
				}

				return res;
			}, {});

		return transformConfig(appConfig);
};
