import { getIsDebug } from "rtUtils/logger";
import { TAG_EVENTS } from "shared/events";
import { WIDGET_EVENTS } from "./consts";

const getWidgetApi = (updateableConfig, eventsManager, render, iframe) => {
	const api =  {
		update: (config) => {
			const prev = updateableConfig.getConfig();
			const updated = updateableConfig.update(config);

			if (updated.container && (updated.container !== prev.container)) {
				iframe.changeContainer(updated.container);
			}

			iframe.update();
		},

		getConfig: () => updateableConfig.getConfig(),

		render: () => {
			render();
		},

		on: (listener) => {
			return eventsManager.addListener(listener);
		},
	};

	if (getIsDebug()) {
		api.downloadGltf = (options) => {
			iframe.sendTagEvent(TAG_EVENTS.EXPORT_GLTF, { params: [options], download: true });
		};

		api.printStructure = () => {
			iframe.sendTagEvent(TAG_EVENTS.PRINT_STRUCTURE, {});
		};

		api.getModelInfo = () => {
			iframe.sendTagEvent(TAG_EVENTS.GET_MODEL_INFO, {});

			return new Promise((resolve) => {
				const off = api.on(({ type, data }) => {
					if (type === WIDGET_EVENTS.MODEL_INFO) {
						off();
						resolve(data);
					}
				});
			});
		};
	}

	return api;
};

export default getWidgetApi;
