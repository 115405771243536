const DEFAULTS = Object.freeze({
	//the container element to embed the iframe in
	container: undefined,
	//(internal) override the app url for the iframe
	appUrl: undefined,
	//automatically show the iframe
	autoShow: true,

	//the model url to load
	modelUrl: undefined,

	//collection of change-configs
	productConfigs: [],
	//the index of the change-config to apply to a mesh/material
	activeConfigs: null,

	//automatically load the model
	autoLoad: true,
	//empty placeholder image
	placeholderImage: undefined,
	//show the progress bar when loading
	showLoadingProgress: true,
	//whether to take a screenshot of the scene when ready
	takeScreenshot: false,
	//track orbit controls changes
	trackOrbitControls: false,

	controls: {
		//deprecated! use ar.enabled instead - TODO: remove
		ar: true,
		//show the controls at the bottom of the viewer
		enabled: true,
		//where to place the button controls (css justify-content value)
		position: "flex-end",
		//whether to show the zoom buttons
		zoom: true,
		//whether to allow mouse scroll-wheel zoom
		mouseZoom: true,
		//allow orbit controls rotate,
		rotate: true,
		//allow orbit controls pan,
		pan: true,
	},

	ar: {
		//whether to show the AR view button
		enabled: true,
		//placement to use for AR viewer: "floor" or "wall"
		placement: "floor",
		//A name for the model. If present, it will be displayed in the UI. The name will be truncated with ellipses after 60 characters.
		title: "",
		//A URL for an external webpage. If present, a button will be surfaced in the UI that intents to this URL when clicked.
		link: "",
		//Whether the model is resizable inside the AR view
		resizable: true,
		//TODO: need to have a page like this available
		fallbackUrl: "https://dimensions.cloudinary.com/ar-fallback.html",
		//a function that renders a custom overlay for the AR QR code
		customArQrRenderer: null,
	},

	rotation: {
		enabled: true,
		//auto rotate speed - https://threejs.org/docs/#examples/en/controls/OrbitControls.autoRotateSpeed
		speed: 2,
		//turn off rotation when user starts to interact with the model
		offOnInteraction: false,
	},

	styles: {
		//"light", "dark", { custom }
		theme: "light",
	},

	hotspots: {
		//whether to show hotspots
		enabled: false,
		//whether to show all hotspots on load
		showOnLoad: true,
		//whether to expand hotspot annotation for mesh when its hovered (showOnLoad must be false)
		showOnHover: false,
		//whether to always expand hotspots
		showExpanded: false,
		//whether to show labels on hotspots
		showLabels: false,
		//the size of the hotspots (small, medium, large), default: medium
		size: "medium",
		//by default expands on hover, if true, will wait for user to click
		expandOnClick: false,
		//whether to get annotation text from mesh's userData object
		contentFromUserData: false,

		//info of hotspots titles & texts { mesh, label, title, text, offset, size, background, color }
		annotations: [],
	},

	renderer: {
		//passed as is to the underlying Dimensions renderer
	},
});

export default DEFAULTS;
