import { WIDGET_EVENTS } from "./consts";
import createIframe from "./iframe";

const initializeViewer = (id, updateableConfig, eventsManager) => {
	eventsManager.trigger(WIDGET_EVENTS.INIT, updateableConfig.getConfig());

	const iframeApi = createIframe(
		id,
		updateableConfig,
		eventsManager
	);

	return {
		iframe: iframeApi,
		render: () => {
			eventsManager.trigger({ type: WIDGET_EVENTS.RENDERED });
		},
	};
};

export default initializeViewer;
