const getCurrentScriptUrl = () => {
	return new URL(document.currentScript.src);
};

const getAppUrl = () => {
	const url = getCurrentScriptUrl();
	url.pathname = url.pathname.replace(/[^/]+$/, "app/index.html");
	url.search = "";
	return url.toString();
};

export default getAppUrl;
